import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ContactPage from "./pages/ContactPage";
import MusicStore from "./pages/MusicStore";
import StudioServicesPage from "./pages/StudioServicesPage";
import LiveEntertainmentPage from "./pages/LiveEntertainmentPage";

import NavigationBar from "./components/NavigationBar";

import AboutPage from "./pages/AboutPage";

import HomePage from "./pages/HomePage";

function App() {
  return (
    <div>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="MusicStore" element={<MusicStore />} />
        <Route path="StudioServices" element={<StudioServicesPage />} />
        <Route path="LiveEntertainment" element={<LiveEntertainmentPage />} />
        <Route path="AboutUs" element={<AboutPage />} />
        <Route path="Contact" element={<ContactPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}
export default App;
