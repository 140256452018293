import React, {
  useContext,
  forwardRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import CarouselTest, { CarouselContext } from "./CarouselTest";
import "../css/css_components/AlbumCarousel.css";

const Pagination = () => {
  const [b, setB] = useState(null);
  const {
    arr1,
    arr2,
    arr3,
    arr4,
    arr5,
    elink1,
    elink2,
    elink3,
    elink4,
    elink5,
    refArr1,
    refArr2,
    refArr3,
    refArr4,
    refArr5,
    imageIcon1,
    imageIcon2,
    imageIcon3,
    imageIcon4,
    imageIcon5,
    myRef,
    paginationRef,
    counter,
    handlePageClick,
    handlePrevClick,
    handleNextClick,
    setAlbumIndex,
    productData,
    state,
  } = useContext(CarouselContext);
  const Pag = () => {
    useEffect(() => {}, [useContext(CarouselContext)]);

    const iconArrays = [
      {
        id: 1,
        imageIcon1,
        nameId: arr1.id,
      },
      {
        id: 0,
        imageIcon2,
        nameId: arr2.id,
      },
      {
        id: 4,
        imageIcon3,
        nameId: arr3.id,
      },
      {
        id: 3,
        imageIcon4,
        nameId: arr4.id,
      },
      {
        id: 2,
        imageIcon5,
        nameId: arr5.id,
      },
    ];

    const m = iconArrays.map((item, i, arr) => {
      const CarouselPaginationIcon = forwardRef(function CarouselPagination(
        props,
        ref
      ) {
        if (
          Object.values(item).includes("icon2") ||
          Object.values(item).toString().includes("image-2-reverse")
        ) {
          return (
            <div className="navigation-state1">
              <div
                onClick={(e) => handlePageClick(e, i)}
                className="navigation-state-item"
                id={item.id}
                ref={(el) => (paginationRef.current[i] = el)}
              />
            </div>
          );
        } else {
          return (
            <div className="navigation-state">
              <div
                onClick={(e) => handlePageClick(e, i)}
                className="navigation-state-child"
                id={item.id}
                ref={(el) => (paginationRef.current[i] = el)}
              />
            </div>
          );
        }
      });

      return <CarouselPaginationIcon key={item.id} ref={myRef} />;
    });
    return m;
  };
  return <Pag />;
};

export default Pagination;
