import React from "react";
import "../css/css_components/SocialMediaFrame.css";
import { Link } from "react-router-dom";

const SocialMediaFrame = () => {
  return (
    <div className="social-media-frame">
      <div className="social-media-links">
        <div className="frame-visit-us">
          <div className="visit-us">Visit us:</div>
        </div>
        <div className="frame-1-social-media-icons">
          <a
            href="https://www.facebook.com/cmrentertainmentmp/"
            target="_blank"
            rel="noopner"
          >
            <img
              className="component-facebook"
              alt=""
              src="/component--facebook.svg"
            />
          </a>
          <a
            href="https://www.youtube.com/@cmrentertainmentmusicprodu916/featured"
            target="_blank"
            rel="noopner"
          >
            <img
              className="component-facebook"
              alt=""
              src="/component-youtube.svg"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaFrame;
