import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import psmImages from "../components/psmImages";
import { ArrowLeft, ArrowRight } from "./SvgComponents";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "swiper/css/navigation";

import "../styles.css";
import "../css/custom-swiper-bullets.css";

import axios from "axios";

// import required modules
import { EffectCoverflow, Pagination, Navigation, A11y } from "swiper/modules";

export default function PhotoAlbum() {
  const swiper = useSwiper();
  const [windoSize, setWindowSize] = useState(window.innerWidth);

  function updateSize() {
    setWindowSize(window.innerWidth);
  }
  // Window listening event for jsx media query
  window.addEventListener("resize", updateSize);

  // let m = swiper.slideNext;

  // IMAGE API --------{
  // const [demoImages, setDemoImages] = useState([]);
  // const URL =
  //   "https://api.unsplash.com/photos/?client_id=bmyO1oBYM81Ro-79Y4QgarKqAhccBXHLMLNxk6Q6RFw";

  // useEffect(() => {
  //   axios.get(URL).then((res) => {
  //     setDemoImages(res.data);
  //   });
  // }, []);

  // console.log(demoImages);

  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        navigation={windoSize >= 768 ? true : false}
        pagination={{ clickable: true }}
        loop={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, A11y]}
        className="mySwiper"
      >
        {psmImages.map((item) => {
          return (
            <div key={item.id}>
              <SwiperSlide key={item.id * 2}>
                <img
                  className=" object-cover aspect-square 1/1"
                  src={item.image}
                  // id={item.id}
                  alt="card-image"
                />
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>
    </>
  );
}
