import React, { useContext, useEffect, useState } from "react";
import CarouselTest, { CarouselContext } from "./CarouselTest";

const MobileSwipe = ({ children }) => {
  const [windoSize, setWindowSize] = useState(window.innerWidth);
  const [screenY, setScreenY] = useState(null);

  function updateSize() {
    setWindowSize(window.innerWidth);
  }
  // Window listening event for jsx media query
  window.addEventListener("resize", updateSize);

  const {
    handlePrevClick,
    handleNextClick,

    cardSwipe,
    setCardSwipe,
  } = useContext(CarouselContext);

  if (windoSize <= 768) {
    //Mobile Swipe
    let touchStartX = 0;
    let touchEndX = 0;
    let m = 0;

    useEffect(() => {
      const querySelect = document.querySelector(".frame");

      if (!querySelect) {
      } else {
        querySelect.addEventListener(
          "touchstart",
          (e) => {
            touchStartX = e.changedTouches[0].screenX;
          },
          { passive: true }
        );

        querySelect.addEventListener(
          "touchend",
          (e) => {
            touchEndX = e.changedTouches[0].screenX;
            let t = 0;

            t = e.changedTouches[0].pageY;

            checkSwipeDirection(t);
          },
          { passive: true }
        );
      }
    }, [cardSwipe]);

    function checkSwipeDirection(t) {
      if (touchEndX > touchStartX) {
        t >= 590 ? "" : handleNextClick();
      }
      if (touchEndX < touchStartX) {
        t >= 590 ? "" : handlePrevClick();
      }

      // setCardSwipe(!cardSwipe);
    }

    return <div>{children}</div>;
  } else {
    return <div>{children}</div>;
  }
};

export default MobileSwipe;
