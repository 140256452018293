import React from "react";
import FooterFrame from "../components/FooterFrame";
import LogoFrame from "../components/LogoFrame";
import PageContent from "../components/PageContent";
import PageHeader from "../components/PageHeader";
import SocialMediaFrame from "../components/SocialMediaFrame";
import Helmet from "react-helmet";
import "../css/css_pages/AboutPage.css";

const AboutPage = () => {
  function content() {
    return (
      <>
        <div className="text-area-mobile">
          <div className="content-div">
            <p className="welcome-to-cmr">
              Welcome to CMR Entertainment Music Productions-your home for all
              of your music needs!!
            </p>
            <p className="welcome-to-cmr">&nbsp;</p>
            <p className="welcome-to-cmr">
              Do you need a sound engineer to run all of the sound for your band
              /event?
            </p>
            <p className="welcome-to-cmr">&nbsp;</p>
            <p className="welcome-to-cmr">
              Are you in need of a live band to perform at your event?
            </p>
            <p className="welcome-to-cmr">&nbsp;</p>
            <p className="welcome-to-cmr">
              Our group offers all of these services and more at extremely
              competitive rates!!
            </p>
            <p className="welcome-to-cmr">&nbsp;</p>
            <p className="conveniently-located-in">
              Conveniently located in Onslow County North Carolina, we have been
              fulfilling the music needs of the surrounding areas for several
              years and we are looking forward to helping you achieve your
              musical desires!!
            </p>
          </div>
        </div>
        ,
      </>
    );
  }

  return (
    //header goes here//

    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
        <meta name="description" content="React application" />
      </Helmet>
      <div className="main-page-about-us">
        <PageHeader pageName="About Us" />

        <PageContent content={content()} />

        <LogoFrame />

        <SocialMediaFrame />

        <FooterFrame />
      </div>
    </>
  );
};

export default AboutPage;
