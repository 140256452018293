import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  forwardRef,
  useReducer,
  useCallback,
} from "react";
import "../css/css_components/DesktopCarousel.css";
import productData from "./productData";
import DesktopCarousel from "./DesktopCarousel";

export const CarouselContext = React.createContext();

export default function CarouselTest({ children }) {
  function clickNext() {}

  const [testClick, setTestClick] = useState();
  const [imageIcon1, setImageIcon1] = useState("icon1");
  const [imageIcon2, setImageIcon2] = useState("icon2");
  const [imageIcon3, setImageIcon3] = useState("icon4 image-2");
  const [imageIcon4, setImageIcon4] = useState("icon3");
  const [imageIcon5, setImageIcon5] = useState("icon");

  const [toggleWindow, setToggleWindow] = useState(false);

  const [cardClassName, setCardClassName] = useState(null);

  const [counter, setCounter] = useState(0);
  const [productCounter, setProductCounter] = useState(0);

  const myRef = useRef();
  const paginationRef = useRef([]);
  const refArr1 = useRef();
  const refArr2 = useRef();
  const refArr3 = useRef();
  const refArr4 = useRef();
  const refArr5 = useRef();

  const [elink1, setElink1] = useState([productData[0].link]);
  const [elink2, setElink2] = useState([productData[1].link]);
  const [elink3, setElink3] = useState([productData[2].link]);
  const [elink4, setElink4] = useState([productData[3].link]);
  const [elink5, setElink5] = useState([productData[4].link]);
  const [mobileCardObj, setMobileCardObj] = useState(1);
  const [albumArrayIndex, setAlbumArrayIndex] = useState(mobileCardObj);

  const [touchMove, setTouchMove] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [prevTouchMove, setPrevTouchMove] = useState(touchMove);
  const [touchEnd, setTouchEnd] = useState(false);

  const [cardSwipe, setCardSwipe] = useState(false);
  // // SWIPE COMPONENT--------{
  // let touchStartX = 0;
  // let touchEndX = 0;

  // useEffect(() => {
  //   const querySelect = document.querySelector(".frame");

  //   if (!querySelect) {
  //   } else {
  //     querySelect.addEventListener(
  //       "touchstart",
  //       (e) => {
  //         touchStartX = e.changedTouches[0].screenX;
  //       },
  //       { passive: true }
  //     );

  //     querySelect.addEventListener(
  //       "touchend",
  //       (e) => {
  //         touchEndX = e.changedTouches[0].screenX;
  //         checkSwipeDirection();
  //       },
  //       { passive: true }
  //     );
  //   }
  // }, [cardSwipe]);

  // function checkSwipeDirection() {
  //   if (touchEndX > touchStartX) {
  //     handleNextClick();
  //   }
  //   if (touchEndX < touchStartX) {
  //     handlePrevClick();
  //   }
  //   setCardSwipe(!cardSwipe);
  // }

  // //Swipe Function
  // function handSwipeCard(e) {
  //   e.preventDefault();
  //   setPrevTouchMove(touchMove);
  //   setTouchMove(e.touches[0].clientX);

  //   console.log(`touchMove-CLIENT X`, touchMove);
  //   console.log(`startTouch-PREVIOUS X`, prevTouchMove);

  //   touchMove > prevTouchMove
  //     ? handleNextClick()
  //     : touchMove < prevTouchMove
  //     ? handlePrevClick()
  //     : "";
  // }

  // console.log("TOUCH END", touchEnd);

  //Test for map
  const [pdata, setPdata] = useState(null);
  useEffect(() => {
    setPdata(productData);
  }, []);

  const card = [
    {
      productCounter: 0,

      endOfRotation: false,
    },
    {
      productCounter: 1,

      endOfRotation: false,
    },
    {
      productCounter: 2,

      endOfRotation: false,
    },
    {
      productCounter: 3,

      endOfRotation: false,
    },
    {
      productCounter: 4,

      endOfRotation: true,
    },
  ];

  const j = productData.map((item, i, array) => {
    let arr = {};
    arr = {
      ...item,
    };
    return arr;
  });

  const [arr1, setArr1] = useState(j[0]);
  const [arr2, setArr2] = useState(j[1]);
  const [arr3, setArr3] = useState(j[2]);
  const [arr4, setArr4] = useState(j[3]);
  const [arr5, setArr5] = useState(j[4]);

  const [state, dispatch] = useReducer(reducer, card);

  function reducer(state, action) {
    switch (action.type) {
      case "increment-indexNumber": {
        return state.map((items, index, array) => {
          return items
            ? items.productCounter >= productData.length - 1
              ? {
                  ...items,
                  productCounter: 0,
                  endOfRotation: false,
                }
              : {
                  ...items,
                  productCounter: items.productCounter + 1,
                }
            : items;
        });
      }
      case "decrement-indexNumber": {
        return state.map((items, index, array) => {
          return items
            ? items.productCounter === 0
              ? {
                  ...items,
                  productCounter: productData.length - 1,
                  endOfRotation: false,
                }
              : {
                  ...items,
                  productCounter: items.productCounter - 1,
                }
            : items;
        });
      }
    }
  }

  // product image change counter
  const productCounter1 = state[0].productCounter;
  const productCounter2 = state[1].productCounter;
  const productCounter3 = state[2].productCounter;
  const productCounter4 = state[3].productCounter;
  const productCounter5 = state[4].productCounter;

  const u = [arr1.id, arr2.id, arr3.id, arr4.id, arr5.id];

  function pCounter() {
    if (productCounter === productData.length - 1) {
      setProductCounter(0);
    } else {
      setProductCounter(productCounter + 1);
    }
  }

  const handlePrevClick = () => {
    setAlbumArrayIndex(productCounter1);

    pCounter();
    dispatch({ type: "decrement-indexNumber" });

    if (counter === 4) {
      setCounter(0);
    } else {
      setCounter(counter + 1);
    }

    if (counter >= 0) {
      switch (counter) {
        case 0:
          setImageIcon1("icon2");
          setImageIcon2("icon4 image-2");
          setImageIcon3("icon3");
          setImageIcon4("icon");
          setImageIcon5("icon1");
          if (Object.values(u).includes(!arr5.id)) {
            setArr5(j[productCounter5]);
            setElink5(productData[productCounter5].link);
          }

          break;

        case 1:
          setImageIcon1("icon4 image-2");
          setImageIcon2("icon3");
          setImageIcon3("icon");
          setImageIcon4("icon1");
          setImageIcon5("icon2");

          if (Object.values(u).includes(!arr4.id)) {
            setArr4(j[productCounter4]);
            setElink4(productData[productCounter4].link);
          }

          break;

        case 2:
          setImageIcon1("icon3");
          setImageIcon2("icon");
          setImageIcon3("icon1");
          setImageIcon4("icon2");
          setImageIcon5("icon4 image-2");

          if (Object.values(u).includes(!arr3.id)) {
            setArr3(j[productCounter3]);
            setElink3(productData[productCounter3].link);
          }

          break;

        case 3:
          setImageIcon1("icon");
          setImageIcon2("icon1");
          setImageIcon3("icon2");
          setImageIcon4("icon4 image-2");
          setImageIcon5("icon3");

          if (Object.values(u).includes(!arr2.id)) {
            setArr2(j[productCounter2]);
            setElink2(productData[productCounter2].link);
          }

          break;

        case 4:
          setImageIcon1("icon1 rotate");
          setImageIcon2("icon2");
          setImageIcon3("icon4 image-2");
          setImageIcon4("icon3");
          setImageIcon5("icon");

          if (Object.values(u).includes(!arr1.id)) {
            setArr1(j[productCounter1]);
            setElink1(productData[productCounter1].link);
          }
          break;

        default:
          // setImageIcon1("icon1")
          // setImageIcon2("icon2")
          // setImageIcon3("icon3")
          // setImageIcon4("icon4 image-2")

          break;
      }
    }
  };

  let y = arr5.toString();

  const handleNextClick = () => {
    if (counter === 0) {
      setCounter(4);
    } else {
      setCounter(counter - 1);
    }

    dispatch({ type: "increment-indexNumber" });
    if (counter >= 0) {
      switch (counter) {
        case 0:
          setImageIcon1("icon1-reverse");
          setImageIcon2("icon2-reverse");
          setImageIcon3("icon4-reverse image-2-reverse");
          setImageIcon4("icon3-reverse");
          setImageIcon5("icon-reverse");

          if (Object.values(u).includes(!arr5.id)) {
            setArr5(j[productCounter5]);
            setElink5(productData[productCounter5].link);
          }
          break;

        case 1:
          setImageIcon1("icon2-reverse");
          setImageIcon2("icon4-reverse image-2-reverse");
          setImageIcon3("icon3-reverse");
          setImageIcon4("icon-reverse");
          setImageIcon5("icon1-reverse");

          if (Object.values(u).includes(!arr4.id)) {
            setArr4(j[productCounter4]);
            setElink4(productData[productCounter4].link);
          }

          break;

        case 2:
          setImageIcon1("icon4-reverse image-2-reverse");
          setImageIcon2("icon3-reverse");
          setImageIcon3("icon-reverse");
          setImageIcon4("icon1-reverse");
          setImageIcon5("icon2-reverse");

          if (Object.values(u).includes(!arr3.id)) {
            setArr3(j[productCounter3]);
            setElink3(productData[productCounter3].link);
          }

          break;

        case 3:
          setImageIcon1("icon3-reverse");
          setImageIcon2("icon-reverse");
          setImageIcon3("icon1-reverse");
          setImageIcon4("icon2-reverse");
          setImageIcon5("icon4-reverse image-2-reverse");

          if (Object.values(u).includes(!arr2.id)) {
            setArr2(j[productCounter2]);
            setElink2(productData[productCounter2].link);
          }

          break;

        case 4:
          setImageIcon1("icon-reverse");
          setImageIcon2("icon1-reverse");
          setImageIcon3("icon2-reverse");
          setImageIcon4("icon4-reverse image-2-reverse");
          setImageIcon5("icon3-reverse");

          if (Object.values(u).includes(!arr1.id)) {
            setArr1(j[productCounter1]);
            setElink1(productData[productCounter1].link);
          }
          break;

        default:
          break;
      }
    }
  };

  const handlePageClick = (e, i) => {
    const paginationId = Number(e.target.id);

    setCounter(paginationId);

    switch (paginationId) {
      case 0:
        setImageIcon1("icon1");
        setImageIcon2("icon2");
        setImageIcon3("icon4 image-2");
        setImageIcon4("icon3");
        setImageIcon5("icon");
        setAlbumArrayIndex(productCounter2);
        break;

      case 1:
        setImageIcon1("icon2");
        setImageIcon2("icon4 image-2");
        setImageIcon3("icon3");
        setImageIcon4("icon");
        setImageIcon5("icon1");
        setAlbumArrayIndex(productCounter1);
        break;

      case 3:
        setImageIcon1("icon3");
        setImageIcon2("icon");
        setImageIcon3("icon1");
        setImageIcon4("icon2");
        setImageIcon5("icon4 image-2");
        setAlbumArrayIndex(productCounter4);
        break;

      case 2:
        setImageIcon1("icon4 image-2");
        setImageIcon2("icon3");
        setImageIcon3("icon");
        setImageIcon4("icon1");
        setImageIcon5("icon2");
        setAlbumArrayIndex(productCounter5);
        break;

      case 4:
        setImageIcon1("icon");
        setImageIcon2("icon1");
        setImageIcon3("icon2");
        setImageIcon4("icon4 image-2");
        setImageIcon5("icon3");
        setAlbumArrayIndex(productCounter3);
        break;

      default:
        break;
    }
  };

  const p = {
    arr1,
    arr2,
    arr3,
    arr4,
    arr5,
    elink1,
    elink2,
    elink3,
    elink4,
    elink5,
    myRef,
    paginationRef,
    refArr1,
    refArr2,
    refArr3,
    refArr4,
    refArr5,
    imageIcon1,
    imageIcon2,
    imageIcon3,
    imageIcon4,
    imageIcon5,
    counter,
    handlePageClick,
    handlePrevClick,
    handleNextClick,
    productData,
    state,
    albumArrayIndex,
    setMobileCardObj,
    mobileCardObj,
    cardSwipe,
    cardClassName,
    setCardSwipe,
    // handSwipeCard,
    setTouchEnd,
    counter,
  };

  return (
    <>
      <CarouselContext.Provider value={p}>{children}</CarouselContext.Provider>
    </>
  );
}
