import React, {
  forwardRef,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";

import "../css/css_components/DesktopCarousel.css";
import CarouselTest, { CarouselContext } from "./CarouselTest";
import Pagination from "./Pagination";
import { ArrowLeft, ArrowRight } from "./SvgComponents";
import MobileSwipe from "./MobileSwipe";

const DesktopCarousel = ({ windowSize, grid }) => {
  // const querySelect = document.querySelector(".description-frame");
  const [commingSoon, setCommingSoon] = useState(null);
  const [showButton, setShowButton] = useState(true);
  const myRef = useRef(null);
  // const [touchEnd, setTouchEnd] = useState(false);
  // const [touchMove, setTouchMove] = useState(0);
  // const [touchStart, setTouchStart] = useState(0);
  // const [prevTouchMove, setPrevTouchMove] = useState(touchMove);

  const {
    arr1,
    arr2,
    arr3,
    arr4,
    arr5,
    refArr1,
    refArr2,
    refArr3,
    refArr4,
    refArr5,
    imageIcon1,
    imageIcon2,
    imageIcon3,
    imageIcon4,
    imageIcon5,
    counter,
    handlePageClick,
    handSwipeCard,
    handlePrevClick,
    handleNextClick,
    setMobileCardObj,
    mobileCardObj,
    setTouchEnd,
    carouselDisplay,
    cardSwipe,
    setCardSwipe,

    state,
  } = useContext(CarouselContext);
  // button Link components

  const ProductButtons = ({ externalLink, buyLink, iconName }) => {
    return (
      <div
        className={
          iconName === "icon2" || iconName === "icon4-reverse image-2-reverse"
            ? "album-desktop-button-banner"
            : "album-desktop-button-banner-reverse-animation"
        }
      >
        <section className="album-details">
          <div
            className="album-button-container"
            style={showButton ? { display: "flex" } : { display: "none" }}
          >
            {/* <button className="play-sample-button">Play Sample</button> */}
            <a
              className="play-sample-button"
              href={externalLink}
              target="_blank"
              rel="noopener"
            >
              Play Sample
            </a>

            <a
              className="buy-button"
              href={buyLink}
              target="_blank"
              rel="noopener"
            >
              Buy Now
            </a>
          </div>
          <div className="album-titlegenre-banner">
            <h3 className="genre">{mobileCardObj.genere}</h3>
            <h1 className="album-title">{mobileCardObj.title}</h1>
          </div>
          <div className="description-frame">
            <div
              className="description-text"
              style={{ paddingLeft: windowSize <= 320 ? "20px" : "" }}
            >
              {mobileCardObj.description}
            </div>
          </div>
        </section>
      </div>
    );
  };

  const DesktopCarouselItems = () => {
    //Mobile Swipe
    // let touchStartX = 0;
    // let touchEndX = 0;
    // useEffect(() => {
    //   const querySelect = document.querySelector(".frame");

    //   if (!querySelect) {
    //   } else {
    //     querySelect.addEventListener(
    //       "touchstart",
    //       (e) => {
    //         touchStartX = e.changedTouches[0].screenX;
    //       },
    //       { passive: true }
    //     );

    //     querySelect.addEventListener(
    //       "touchend",
    //       (e) => {
    //         touchEndX = e.changedTouches[0].screenX;
    //         checkSwipeDirection();
    //       },
    //       { passive: true }
    //     );
    //   }
    // }, [cardSwipe]);

    // function checkSwipeDirection() {
    //   if (touchEndX > touchStartX) {
    //     handleNextClick();
    //   }
    //   if (touchEndX < touchStartX) {
    //     handlePrevClick();
    //   }
    //   setCardSwipe(!cardSwipe);
    // }
    //  This loops over and searches for classNames in the array then returns the item which equals the className
    const t = [
      { imageIcon1 },
      { imageIcon2 },
      { imageIcon3 },
      { imageIcon4 },
      { imageIcon5 },
    ];

    let r = " ";

    t.map((item, index, arr) => {
      if (
        Object.values(item).toString() === "icon4-reverse image-2-reverse" ||
        Object.values(item).toString() === "icon2"
      ) {
        r = Object.values(item).toString();
      } else {
        // console.log("no");
      }
    });

    // This maps over the card and returns the apropreiate Jsx info
    const myCard = [
      {
        className: imageIcon1,
        arr: arr1,
        ref: refArr1,
      },
      {
        className: imageIcon2,
        arr: arr2,
        ref: refArr2,
      },
      {
        className: imageIcon3,
        arr: arr3,
        ref: refArr3,
      },
      {
        className: imageIcon4,
        arr: arr4,
        ref: refArr4,
      },
      {
        className: imageIcon5,
        arr: arr5,
        ref: refArr5,
      },
    ];

    useEffect(() => {
      const checkClassName = myCard.map((item) => {
        item.className.toString() === r ? setMobileCardObj(item.arr) : "";
      });
    }, [mobileCardObj]);

    //Album comming soon
    let w = {};
    useEffect(() => {
      const checkAlbum = myCard.map((item) => {
        if (
          item.className === "icon4-reverse image-2-reverse" ||
          item.className === "icon2"
        ) {
          item.arr.title === "Let It Go" ? [setShowButton(false)] : " ";
        } else {
          setShowButton(true);
        }
      });
    }, [mobileCardObj]);

    // function handSwipeCard(e) {
    //   setTouchEnd(handleNextClick);
    //   touchEnd;
    //   setPrevTouchMove(touchMove);
    //   setTouchMove(e.touches[0].clientX);

    //   console.log(`touchMove-CLIENT X`, touchMove);
    //   console.log(`startTouch-CLIENT X`, prevTouchMove);

    //   touchMove > prevTouchMove
    //     ? console.log("CLICK NEXT")
    //     : touchMove < prevTouchMove
    //     ? console.log("CLICK PREVIOUS")
    //     : "";
    // }

    // console.log(prevTouchMove);
    // console.log(touchEnd);

    // const swipeCard = useCallback(() => {
    //   const onMoveHandle = () => {
    //     // const j = touchMove.touches[0].clientX;
    //     // const q = startTouch.touches[0].clientX;

    //     const p = myRef;

    //     console.log(`touchMove-CLIENT X`, touchMove);
    //     console.log(`startTouch-CLIENT X`, touchStart);
    //   };
    // }, [touchEnd]);

    // const myTest = useCallback(() => {
    //   onMoveHandle;
    // }, [touchEnd]);

    // const onTouchEnd = (e) => {
    //   setTouchEnd(!touchEnd);
    //   console.log(`TouchEnd`);
    // };

    // console.log(touchEnd);

    const cardJsx = myCard.map((item, index, array) => {
      let p = [];
      // checkClassName(item);

      // item.className != "icon4-reverse image-2-reverse" || "icon2"
      //   ? " "
      //   : item.arr.title === "Let It Go"
      //   ? (p = <h1 className=" z-10 text-4xl font-sans pl-10	">Comming Soon</h1>)
      //   : " ";

      if (
        item.className === "icon4-reverse image-2-reverse" ||
        item.className === "icon2"
      ) {
        item.arr.title === "Let It Go"
          ? [
              (p = (
                <h1 className=" z-10 text-4xl font-sans pl-10	">Comming Soon</h1>
              )),
            ]
          : " ";
      } else {
      }

      return (
        <div className={item.className} key={item.arr.id}>
          {p}
          <img
            className="front"
            alt=""
            src={item.arr.image}
            id={item.arr.id}
            ref={item.ref}
          />
          <img
            className="back"
            alt=""
            src={item.arr.image}
            id={item.arr.id}
            ref={item.ref}
          />

          <ProductButtons
            iconName={item.className}
            externalLink={item.arr.link}
            buyLink={item.arr.buyLink}
          />
        </div>
      );
    });

    return (
      <div className="desktop-carousel-frame" style={{ display: grid }}>
        <section className="desktop-carousel">
          <div className="frame" ref={myRef}>
            <MobileSwipe>{cardJsx}</MobileSwipe>
          </div>
          <div className="arrows-parent">
            <button onClick={handlePrevClick}>
              <ArrowLeft size="25" />
            </button>

            <div className="navigation-state-group">
              <Pagination />
            </div>
            <button onClick={handleNextClick}>
              <ArrowRight size="25" />
            </button>
          </div>
        </section>
      </div>
    );
  };
  // main function return jsx

  return (
    <>
      <DesktopCarouselItems />
    </>
  );
};

export default DesktopCarousel;
