const psmImages = [
  {
    id: [1],
    image: "/psm-pic-1.jpg",
    description: "Description goes here",
  },
  {
    id: [2],
    image: "/psm-pic2.jpg",
    description: "Description goes here",
  },
  {
    id: [3],
    image: "/psm-pic3.jpg",
    description: "Description goes here",
  },
  {
    id: [4],
    image: "/psm-pic4.jpg",
    description: "Description goes here",
  },
  {
    id: [5],
    image: "/psm-pic5.jpg",
    description: "Description goes here",
  },
  {
    id: [6],
    image: "/psm-pic6.jpg",
    description: "Description goes here",
  },
  // {
  //   id: [7],
  //   image: "/psm-pic6.jpg",
  //   description: "Description goes here",
  // },
  // {
  //   id: [8],
  //   image: "/psm-pic4.jpg",
  //   description: "Description goes here",
  // },
  // {
  //   id: [9],
  //   image: "/psm-pic4.jpg",
  //   description: "Description goes here",
  // },
  // {
  //   id: [10],
  //   image: "/psm-pic6.jpg",
  //   description: "Description goes here",
  // },
  // {
  //   id: [11],
  //   image: "/psm-pic6.jpg",
  //   description: "Description goes here",
  // },
];

export default psmImages;
