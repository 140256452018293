import React from "react";
import "../css/css_pages/LiveEntertainmentPage.css";
import FooterFrame from "../components/FooterFrame";
import LogoFrame from "../components/LogoFrame";
import PageContent from "../components/PageContent";
import PageHeader from "../components/PageHeader";
import SocialMediaFrame from "../components/SocialMediaFrame";
import { Helmet } from "react-helmet";
import PhotoAlbum from "../components/PhotoAlbum";

const LiveEntertainmentPage = () => {
  const content = [
    <div className="text-area-mobile" key={1}>
      <div className="content-div">
        <p className="welcome-to-cmr">
          Welcome to CMR Entertainment Music Productions-your home for all of
          your music needs!!
        </p>
        <p className="welcome-to-cmr">&nbsp;</p>
        <p className="welcome-to-cmr">
          Do you need a sound engineer to run all of the sound for your band
          /event?
        </p>
        <p className="welcome-to-cmr">&nbsp;</p>
        <p className="welcome-to-cmr">
          Are you in need of a live band to perform at your event?
        </p>
        <p className="welcome-to-cmr">&nbsp;</p>
        <p className="welcome-to-cmr">
          Our group offers all of these services and more at extremely
          competitive rates!!
        </p>
        <p className="welcome-to-cmr">&nbsp;</p>
        <p className="conveniently-located-in">
          Conveniently located in Onslow County North Carolina, we have been
          fulfilling the music needs of the surrounding areas for several years
          and we are looking forward to helping you achieve your musical
          desires!!
        </p>
      </div>
    </div>,
  ];
  return (
    <>
      <div className="main-page-live">
        <Helmet>
          <title>Live Entertainment</title>
          <meta name="description" content="Music Production Company" />
        </Helmet>
        <PageHeader pageName=" Live Entertainment" />
        <div className="frame-band-title">
          <div className="band-text-section">
            <div className="the-pamlico">The Pamlico</div>
            <div className="the-pamlico">Sound Machine</div>
          </div>
        </div>

        <PhotoAlbum />

        <PageContent content={content} />
        <LogoFrame />
        <SocialMediaFrame />
        <FooterFrame />
      </div>
    </>
  );
};

export default LiveEntertainmentPage;
