import React from "react";
import { Helmet } from "react-helmet";
import "../css/css_pages/HomePage.css";

const HomePage = () => {
  return (
    <div className="iphone-13-mini-frame-home">
      <Helmet>
        <title>CMR Music Entertainment Productions</title>
        <meta name="description" content="Music Production Company" />
      </Helmet>
      <div className="main-section5">
        <div className="section-12">
          <div className="feel-the-music-wrapper">
            <b className="feel-the-music">feel the music</b>
          </div>
          <div className="your-source-for-live-entertain-wrapper">
            <b className="feel-the-music">
              <p className="your-source-for">
                your source for live entertainment
              </p>
              <p className="your-source-for"> and recorded music production</p>
            </b>
          </div>
        </div>
        <div className="cmr-logo-div5">
          <img className="cmrlogo-1-icon5" alt="" src="/cmrlogo-15@2x.png" />
        </div>
        <div className="radical-gradient-div">
          <div className="radical-gradient-div-child" />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
