import React from "react";
import "../css/css_components/PageHeader.css";

const PageHeader = (props) => {
  return (
    <div className="pageheader">
      <div className="about-us">{props.pageName}</div>
    </div>
  );
};

export default PageHeader;
