import React from "react";
import "../css/css_components/FooterFrame.css";
import { Link, Route } from "react-router-dom";

const FooterFrame = () => {
  return (
    <div className="footer-frame">
      <section className="mobile-footer5">
        <div className="cmr-text-parent2">
          <div className="cmr-text5">
            <Link
              to={"/src/pages/LiveEntertainmentPage.js"}
              className="cmr-entertainment-music-container6"
            >
              <p className="cmr5">{`CMR `}</p>
              <p className="cmr5">{`Entertainment `}</p>
              <p className="music-productions6">Music Productions</p>
            </Link>
            <div className="cmr-text-child2" />
          </div>
          <div className="links17">
            <Link
              to={"/srcpages/HomePage.js"}
              className="cmr-entertainment-music-container6"
            >
              Home
            </Link>
            <Link
              to={"/src/pages/AboutPage.js"}
              className="cmr-entertainment-music-container6"
            >
              About
            </Link>
            <Link
              to={"/src/pages/LiveEntertainmentPage.js"}
              className="cmr-entertainment-music-container6"
            >
              <p className="cmr5">{`Live `}</p>
              <p className="music-productions6">Entertainment</p>
            </Link>
            <Link
              to={"/src/pages/StudioServicesPage.js"}
              className="cmr-entertainment-music-container6"
            >
              Studio Services
            </Link>
            <Link
              to={"/src/pages/MusicStore.js"}
              className="cmr-entertainment-music-container6"
            >
              Music Store
            </Link>
            <Link
              to={"/src/pages/ContactPage.js"}
              className="cmr-entertainment-music-container6"
            >
              Contact
            </Link>
          </div>
          <div className="frame-parent5">
            <img className="frame-child4" alt="" src="/frame-7.svg" />
            <div className="div6">252-364-8303</div>
          </div>
        </div>
        <div className="cmr-rights-reserved5">
          <div className="copyright-2015-container5">
            <p className="copyright-20155">
              Copyright @ 2015 CMR Music Entertainment Productions
            </p>
            <p className="music-productions6">{`All Rights reserved  `}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FooterFrame;
