import React from "react";


 const ArrowLeft =({fill,size})=>{
     return(
        <svg width={size ||"25"} height={size ||"25"} viewBox="0 0 25 25" fill={fill || "white"} xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 12.0576H5.5" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5 19.0576L5.5 12.0576L12.5 5.05762" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>)

 };

  const ArrowRight =({fill,size})=>{
     return(
            <svg width={size ||"25"} height={size ||"25"} viewBox="0 0 25 25" fill={fill || "white"} xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 12.0576H19.5" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.5 5.05762L19.5 12.0576L12.5 19.0576" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>)

  

 }
export {ArrowLeft, ArrowRight};
