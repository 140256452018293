import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import "../css/css_components/NavigationBar.css";
import "../css/css_components/MobleNavbarClosed.css";
import "../css/css_components/MobleNavbarOpen.css";
import "../css/css_components/DesktopNavBarView.css";
import { Link, NavLink } from "react-router-dom";

const NavigationBar = (...props) => {
  const [toggle, setToggle] = useState(false);
  const [isClicked, setisClicked] = useState(false);
  const [windoSize, setWindowSize] = useState(window.innerWidth);

  function updateSize() {
    setWindowSize(window.innerWidth);
  }
  // Window listening event for jsx media query
  window.addEventListener("resize", updateSize);

  // Mobile View Closed
  const MobleNavbarClosed = () => {
    const myContainer = {
      initial: { rotate: "0deg" },
      flip: { rotate: "90deg" },
    };

    const handleClick = () => {
      setToggle(!toggle);
      setisClicked(true);
    };

    return (
      <header className="property-1moble-navbar-closed6">
        <img className="cmrlogo-icon18" alt="" src="/cmrlogo9@2x.png" />

        <button className="helloButon" onClick={handleClick}>
          <AnimatePresence initial={!toggle ? false : true}>
            <motion.img
              className="menu-icon6"
              alt=""
              src="/menu2@2x.png"
              variants={myContainer}
              initial={toggle ? "initial" : "flip"}
              animate={!toggle ? "initial" : "flip"}
              transition={{ duration: 1 }}
            />
          </AnimatePresence>
        </button>
      </header>
    );
  };

  // Mobile View Open //
  const MobleNavbarOpen = () => {
    const myContainer = {
      initial: { display: "flex", height: "auto" },
      drawer: { height: "0" },
    };

    return (
      <>
        <MobleNavbarClosed />
        <AnimatePresence initial={!toggle ? false : true}>
          <motion.nav
            className="property-1moble-navbar-open6"
            variants={myContainer}
            initial={toggle ? "initial" : "drawer"}
            animate={!toggle ? "initial" : "drawer"}
            transition={{ duration: 1 }}
          >
            <div className="links18" id="openLinks">
              <img
                className="navbarpic-icon12"
                alt=""
                src="/navbarpic4@2x.png"
              />
              <Link to={"/Home"} className="home18">
                Home
              </Link>
              <Link to={"AboutUs"} className="about18">
                About
              </Link>
              <Link to={"LiveEntertainment"} className="live-entertainment20">
                Live Entertainment
              </Link>
              <Link to={"StudioServices"} className="studio-services18">
                Studio Services
              </Link>
              <Link to={"MusicStore"} className="music-store18">
                Music Store
              </Link>
              <Link to={"Contact"} className="contact18">
                Contact
              </Link>
            </div>
            <img
              className="property-1moble-navbar-open-child4"
              alt=""
              src="/frame-32@2x.png"
            />
          </motion.nav>
        </AnimatePresence>
      </>
    );
  };

  // Desktop View //

  const DesktopView = () => {
    return (
      <nav className="property-1desktop-view26">
        <div className="moble-navbar13">
          <img className="cmrlogo-icon20" alt="" src="/cmrlogo11@2x.png" />
          <div className="menu13">
            <img
              className="combined-shape-icon13"
              alt=""
              src="/combined-shape.svg"
            />
          </div>
        </div>
        <div className="links19">
          <img className="navbarpic-icon13" alt="" src="/navbarpic41@2x.png" />
          <Link to={"/Home"} className="home19 desktopNavBar">
            Home
          </Link>
          <Link to={"AboutUs"} className="about19 desktopNavBar">
            About
          </Link>
          <Link
            to={"LiveEntertainment"}
            className="live-entertainment21 desktopNavBar"
          >
            Live Entertainment
          </Link>
          <Link
            to={"StudioServices"}
            className="studio-services19 desktopNavBar"
          >
            Studio Services
          </Link>
          <Link to={"MusicStore"} className="music-store19 desktopNavBar">
            Music Store
          </Link>
          <Link to={"Contact"} className="contact19 desktopNavBar">
            Contact
          </Link>
        </div>
      </nav>
    );
  };

  //Return main jsx//
  return (
    <div className="navigation-bar6">
      {windoSize >= 961 ? <DesktopView /> : <MobleNavbarOpen />}
    </div>
  );
};

export default NavigationBar;
