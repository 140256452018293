import React from "react";
import "./LogoFrame.css";
import { Link } from "react-router-dom";

const LogoFrame = () => {
  return (
    <div className="logo-frame">
      <Link path="/src/pages/AboutPage.js" className="cmr-logo">
        <img className="cmrlogo-1-icon" alt="" src="/cmrlogo-1@2x.png" />
      </Link>
    </div>
  );
};

export default LogoFrame;
