import React, { useState } from "react";
import "../css/css_pages/MusicStore.css";
import FooterFrame from "../components/FooterFrame";
import LogoFrame from "../components/LogoFrame";
import PageContent from "../components/PageContent";
import PageHeader from "../components/PageHeader";
import SocialMediaFrame from "../components/SocialMediaFrame";

import DesktopCarousel from "../components/DesktopCarousel";
import CarouselTest from "../components/CarouselTest";
import { Helmet } from "react-helmet";

const MusicStore = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  function updateSize() {
    setWindowSize(window.innerWidth);
  }

  window.addEventListener("resize", updateSize);

  function content() {
    return (
      <>
        <div className="text-area-mobile">
          <div className="content-div-frame">
            <div className="about-page">
              <p className="welcome-to-cmr">
                Do you need a live band to perform at your next event? Do you
                need a studio to create your next song/album? CMR Entertainment
                Music Productions offers both live performances services and
                studio services.
              </p>
              <p className="welcome-to-cmr">&nbsp;</p>
              <p className="welcome-to-cmr">
                CMR Entertainment Music Productions is a business based out of
                Eastern North Carolina that offers music production services as
                well as music entertainment services. Our group was created 2005
                by twin brothers, Marlo and Carlo Rouse. They each have over 2
                decades of experience in the music profession in live
                performance, singing , song writing, as extensive knowledge of
                playing multiple instruments including bass, drums, keyboard, &
                guitar. They have a passion for working with music and helping
                people achieve the sound the are looking for!!
              </p>
              <p className="welcome-to-cmr">&nbsp;</p>

              <p className="welcome-to-cmr">
                The services we offer are split into studio services and live
                entertainment services. Our studio services include song
                recording. Song writing, music composition, album/cd creation &
                packaging. music video recording , and mixing & mastering and
                more!! Check out the “Studio Services” tab above for more
                information!! The live entertainment services include sound
                engineering for your event/band and providing your own live
                bands to perform at your events. Check out the “live
                Entertainment” tab above for info on our live band!
              </p>
              <p className="welcome-to-cmr">&nbsp;</p>

              <p className="welcome-to-cmr">
                We look forward to helping you with your music needs. Give us a
                call or send us and email to schedule a band or to set up an
                appointment for studio services!!
              </p>
            </div>
          </div>
        </div>
        ,
      </>
    );
  }

  return (
    <>
      <div className="main-page-music-store">
        <Helmet>
          <title>Music Store</title>
          <meta name="description" content="Music Production Company" />
        </Helmet>
        <PageHeader pageName=" Music Store" />

        <CarouselTest>
          <DesktopCarousel windowSize={windowSize} />
        </CarouselTest>

        <PageContent content={content()} />

        <LogoFrame />

        <SocialMediaFrame />

        <FooterFrame />
      </div>
    </>
  );
};

export default MusicStore;
