import React from "react";
import "../css/css_components/ContactInfoFrame.css";

const ContactInfoFrame = () => {
  return (
    <div className="contact-info-frame">
      <section className="contact-info">
        <div className="frame-group">
          <div className="cmr-entertainment-music-produc-wrapper">
            <div className="cmr-entertainment-music-container1">
              <p className="cmr-entertainment">{`CMR Entertainment `}</p>
              <p className="music-productions1">Music Productions</p>
            </div>
          </div>
          <div className="po-box-1415-parent">
            <div className="richlands-nc">PO Box 1415</div>
            <div className="richlands-nc">Richlands , NC 28575</div>
          </div>
        </div>

        <div className="frame-group2">
          <div className="manager-parent">
            <div className="richlands-nc">Manager</div>
            <div className="frances-rouse">Frances Rouse</div>
          </div>
          <div className="manager-parent">
            <div className="richlands-nc">Phone</div>
            <div className="frame-container">
              <img className="frame-item" alt="" src="/frame-244.svg" />
              <div className="richlands-nc">252-364-8303</div>
            </div>
          </div>
          <div className="manager-parent">
            <div className="richlands-nc">Email</div>
            <div className="group-parent">
              <img className="frame-inner" alt="" src="/group-257.svg" />
              <div className="cmrcmrentertainmentnet">
                cmr@cmrentertainment.net
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactInfoFrame;
