import React from "react";
import "../css/css_pages/ContactPage.css";
import ContactInfoFrame from "../components/ContactInfoFrame";
import FooterFrame from "../components/FooterFrame";
import LogoFrame from "../components/LogoFrame";
import PageHeader from "../components/PageHeader";
import SocialMediaFrame from "../components/SocialMediaFrame";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <>
      <div className="main-page-contact">
        <Helmet>
          <title>Contact Us</title>
          <meta name="description" content="Music Production Company" />
        </Helmet>

        <PageHeader pageName="Contact Us" />

        <ContactInfoFrame />

        <LogoFrame />

        <SocialMediaFrame />

        <FooterFrame />
      </div>
    </>
  );
};

export default ContactPage;
