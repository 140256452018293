const productData = [
  {
    id: 1,
    genere: "Holiday",
    title: "There's Nothing Else Like Christmas",
    artist: "Toris Green",
    image: "/images/There's Nothing Else Like Christmas album cover.jpg",
    price: " ",
    description: `" There's Nothing Else Like Christmas" is an album of classic
    Christmas songs with a new twist, getting you into the Chistmas spirit like never before.`,
    link: "https://www.amazon.com/music/player/albums/B003CNNK1S",
    buyLink: "https://www.amazon.com/music/player/albums/B003CNNK1S",
  },
  {
    id: 2,
    genere: "Smooth Jazz & Easy Listening",
    title: "Jam,Vol.1",
    artist: "Epic Connection",
    image: "/images/Epic Connection album cover.jpg",
    price: "",
    description: `Epic Connection consist of various musicians collaborating their skills
    and talents to create soulful R&B Jazz. Dance and other music genres to attract
    a diverse group of music lovers!`,
    link: "https://www.amazon.com/music/player/albums/B00K1I5ILM",
    buyLink: "https://www.amazon.com/music/player/albums/B00K1I5ILM",
  },
  {
    id: 3,
    genere: "Dance,Pop,and R&B",
    title: "Get Away",
    artist: "Jermaine",
    image: "/images/Get Away album cover.jpg",
    price: " ",
    description: `Retro R&B and Pop returns with Jermaine's debut Album "Get Away".
    If you like New Edition, Michael Jackson and other music from the 80's & 90's,
    this is the right album for you! Strong ballads and hooky choruses that make you
    want to dance and get away.`,
    link: "https://www.amazon.com/music/player/albums/B00SV47D5C",
    buyLink: "https://www.amazon.com/music/player/albums/B00SV47D5C",
  },

  {
    id: 4,
    genere: "Carolina Beach/Shag",
    title: "Do Me Good",
    artist: "Tj Jones & The Pamlico Sound Machine",
    image: "/images/do-me-good-album-cover-1@2x.png",
    price: "Price goes here",
    description: `These Shag and swing tunes capture the Carolina Beach Music ear!
    The title song, "Do Me Good", places the entire album in the juke joints
    of old and sets the table for having a fun time! This is dance music
    for the "holding hands" generations who have not forgotten how it
    feels to hold a partner close.`,
    link: "https://www.amazon.com/music/player/albums/B002DFUZZA",
    buyLink: "https://www.amazon.com/music/player/albums/B002DFUZZA",
  },

  {
    id: 5,
    genere: "Gospel & Contemporary Christian ",
    title: "Let It Go",
    artist: "Toris Green",
    image: "/images/Let it Go album cover.jpg",
    price: "",
    description: `Urban and soulful gospel music that's soothing to the spirit.
    This album will put in your mind artists such as Fred Hammond, Marvin Sapp,
    The Winnans and many more notable gospel artist.`,
    link: "https://www.amazon.com/music/player/albums/B002DFUZZA",
    buyLink: "https://www.amazon.com/music/player/albums/B002DFUZZA",
  },
];
export default productData;
